module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BodilessJS","short_name":"Bodiless","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8a8dbb6172315bdfc32dc2973ba64215"},
    },{
      plugin: require('../node_modules/@bodiless/gatsby-theme-bodiless/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@canvasx/gatsby-plugin-onetrust/gatsby-browser.js'),
      options: {"plugins":[],"mainScriptDisabled":true,"scriptUrl":"//optanon.blob.core.windows.net/consent/bda1305e-a3e8-44ea-ade9-5f69641eff30-test.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
